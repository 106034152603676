import { all, fork } from 'redux-saga/effects';
import campaignsSaga from './campaigns';
import configurationSaga from './configuration';
import deviceListSaga from './device-list';
import issueSaga from './issue';
import issueLabelSaga from './issue-label';
import labelSaga from './label';
import projectSaga from './projects';
import scopeSaga from './scope';
import testCaseSaga from './test-case';
import testStepSaga from './test-step';
import testSuiteSaga from './test-suite';
import osVersionSaga from './os-version';
import modelSaga from './model';
import deviceSaga from './device';
import operatingSystemSaga from './operating-system';

function* sagas(): Generator {
    yield all([
        fork(campaignsSaga),
        fork(configurationSaga),
        fork(deviceListSaga),
        fork(issueLabelSaga),
        fork(issueSaga),
        fork(labelSaga),
        fork(projectSaga),
        fork(scopeSaga),
        fork(testCaseSaga),
        fork(testStepSaga),
        fork(testSuiteSaga),
        fork(osVersionSaga),
        fork(modelSaga),
        fork(deviceSaga),
        fork(operatingSystemSaga),
    ]);
}

export default sagas;
