import { combineReducers } from 'redux';
import alerts from './alerts';
import campaigns from './campaign';
import configuration from './configuration';
import deviceList from './device-list';
import issues from './issues';
import issueLabels from './issue-labels';
import projects from './projects';
import testSuite from './test-suite';
import activities from './activities';
import osVersion from './os-versions';
import models from './models';
import devices from './devices';
import operatingSystem from './operating-system';

const reducers = combineReducers({
    activities,
    alerts,
    campaigns,
    configuration,
    deviceList,
    issues,
    issueLabels,
    projects,
    testSuite,
    osVersion,
    models,
    devices,
    operatingSystem,
});

export default reducers;
