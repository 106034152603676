import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ReactElement, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import Notification from '../components/Notification';
import SplashScreen from '../components/SplashScreen';
import { AncestorProvider } from '../contexts/AncestorContext';
import { AuthProvider } from '../contexts/AuthContext';
import { SettingsProvider, useSettings } from '../contexts/SettingsContext';
import router from '../routes';
import { createTheme, GlobalStyles } from '../theme';
const App = (): ReactElement => {
    const { settings } = useSettings();

    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <AncestorProvider>
                    <SettingsProvider>
                        <Suspense fallback={<SplashScreen />}>
                            <Notification />
                            <AuthProvider>
                                <RouterProvider router={router} />
                            </AuthProvider>
                        </Suspense>
                    </SettingsProvider>
                </AncestorProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
